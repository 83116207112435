import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"

const BlogPostTemplate = ({ data, location }) => {
  const post = data.markdownRemark
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const minutes = timeToRead => {
    if (timeToRead > 1) return "mins"
    else return "min"
  }
  //const category = (post.frontmatter.category).toUpperCase()

  return (
    <Layout location={location} title={siteTitle}>
      <Seo
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
      />
      <div className="flex flex-col sm:flex-row bg-white p-4">
        <div className="basis-1/3 w-full flex flex-col gap-2 items-center justify-start p-8">
          <span className="font-bold">Contents</span>
          <p dangerouslySetInnerHTML={{ __html: post.tableOfContents }}></p>
        </div>
        <article
          className="basis-1/3 w-full flex flex-col gap-8 items-center justify-center p-2"
          itemScope
          itemType="http://schema.org/Article"
        >
          <header className="flex flex-col gap-4 text-center">
            <p className="text-lg font-semibold text-[#66746C]">
              {post.frontmatter.category.toUpperCase()}
            </p>
            <h1 className="text-3xl sm:text-5xl font-bold" itemProp="headline">
              {post.frontmatter.title}
            </h1>
            <p className="text-lg font-normal">
              {post.frontmatter.date} ▪ {post.timeToRead} {minutes()}
            </p>
          </header>
          <section
            className="blog-post"
            dangerouslySetInnerHTML={{ __html: post.html }}
            itemProp="articleBody"
          />
          <footer></footer>
        </article>
        <div className="basis-1/3 w-full flex flex-col gap-2 items-center justify-start p-8">
          Author
        </div>
      </div>
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($id: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        category
      }
      timeToRead
      tableOfContents(maxDepth: 2)
    }
  }
`
